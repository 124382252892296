import { atom } from "jotai";
import mannaNafnaStelpuHeiti from "../data/mannaNafnaStelpuHeiti";

export const BaunaHeitiAtom = atom(mannaNafnaStelpuHeiti);
export const newBaunaDataAtom = atom([]);
export const fbDataAtom = atom({});
export const filteredStatusAtom = atom({});

export const showOnlyListLengthAtom = atom(-1);
export const iselfStatusAtom = atom("");
export const sammalaKannskiAtom = atom([]);
export const sammalaVilEkkiAtom = atom([]);

export const baunaListaDataAtom = atom([]);
export const baunaListaDataLengthAtom = atom(-1);

export const userNameAtom = atom({});
export const baunaObjAtom = atom({});
export const showStatusesAtom = atom({
    Kannski: true, 
    VilEkki: true, 
    TheRest: true, 
    SammalKannski: true, 
    SammalVilEkki: true, 
    BidurSvars: true,
    SynaAllt: true
});
export const showOnlyAtom = atom({
    Kannski: false, 
    VilEkki: false, 
    TheRest: false, 
    SammalKannski: false, 
    SammalVilEkki: false, 
    BidurSvars: false,
});

export const allListStatesAtom = atom({
    Kannski: {status: 1, include: true, showOnly: false},
    VilEkki: {status: 1, include: true, showOnly: false},
    Answered: {status: 1, include: true, showOnly: false},
    TheRest: {status: 1, include: true, showOnly: false},
    SammalKannski: {status: 1, include: true, showOnly: false},
    SammalVilEkki: {status: 1, include: true, showOnly: false},
    BidurSvars: {status: 1, include: true, showOnly: false}
});
export const imyselfAtom = atom("");
export const partnerAtom = atom("");

export const showTotalListAtom = atom(true);
export const showSammalaKannskiAtom = atom(false);
export const showSammalaVilEkkiAtom = atom(false);
export const showKannskiAtom = atom(1);
export const showVilEkkiAtom = atom(1);
export const showTheRestAtom = atom(1);

export const filterSearchAtom = atom("");
export const baunaNafnToAdd = atom("");

export const filteredSearchBaunaHeiti = atom(
    (get) => get(filterSearchAtom).includes('*') 
            ? get(BaunaHeitiAtom).filter(n => new RegExp('^' + get(filterSearchAtom).replace(/\*/g, '.*') + '$', "i").test(n)) 
            : get(BaunaHeitiAtom).filter((n) => n.toLowerCase().includes(get(filterSearchAtom).toLowerCase()) ) 
);

export const addToBaunaHeitiAtom = atom(
    (get, set) => set(...get(BaunaHeitiAtom), get(baunaNafnToAdd))
);

