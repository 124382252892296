import React, {useState} from "react";
import "./NewStyles.css";
import { useAtom } from "jotai";
import { baunaListaDataAtom } from "./newBaunaAtoms";
import { baunaListaDataLengthAtom } from "./newBaunaAtoms";

// import { filteredSearchBaunaHeiti } from "./newBaunaAtoms";
// import { BaunaHeitiAtom } from "./newBaunaAtoms";

import LinuBaun from "./LinuBaun";

// import data from "../data/testNewBaunaData";

import { useVirtual } from "react-virtual";

export default function BaunaListi({data}) {

  const consolelog = false;

    // const [baunaListaDataAtm, SETbaunaListaDataAtm] = useAtom(baunaListaDataAtom);
    const [baunaListaDataLengthAtm, SETbaunaListaDataLengthAtm] = useAtom(baunaListaDataLengthAtom);
    // const [filtered] = useAtom(filteredSearchBaunaHeiti);
    SETbaunaListaDataLengthAtm(data.length)
    // AtomNewBaunirSET([...data])

  if (consolelog) {
    console.log("...... Þetta er data-lengdin á gögnum til Virtualized-Lista:")
    console.log(" ....    ")  
    console.log(" ....     data.length = ", data.length)
    console.log(" ....    ")
    console.log(" .... ........................................ .............")
  }

  const [user, setUser] = useState("A");
  const [userIsA, setUserIsA] = useState(true);
  const parentRef = React.useRef();

  const rowVirtualizer = useVirtual({
    size: data.length,
    parentRef,
    estimateSize: React.useCallback(() => 190, [])
  });
 
  const switchUsers = () => {
    setUserIsA(!userIsA)
    setUser(`${userIsA ? 'B' : 'A'}`)
  }
  
  return (
    <div className="newBumbskList-container">
      {/* <img src="./cake.png" className="logo" alt="Happy birthday cake!" /> */}
      <div 
        className="switch-users-button"
        onClick={switchUsers}
        >
            {`USER = ${user}`}
        </div>
      <div
        ref={parentRef}
        style={{
          height: `100vh`,
          width: "100%",
          overflow: "auto"
        }}
      >
        <div className="ListInner"
          style={{
            // Set the scrolling inner div of the parent to be the
            // height of all items combined. This makes the scroll bar work.
            height: (`${200 + rowVirtualizer.totalSize}px`),
            paddingBottom: '200px',
            width: "100%",
            position: "relative"
          }}
        >
          {
            // {filtered.map((n) => kannskiList.includes(n) && <RenderListRow n={n} key={n}/>)}
            rowVirtualizer.virtualItems.map(virtualRow => (
                
                <div
                    key={virtualRow.index}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: `${virtualRow.size}px`, 
                        transform: `translateY(${virtualRow.start}px)` 
                    }}
                >
                  <LinuBaun 
                      baun={data[virtualRow.index]}
                      baunaNafn={data[virtualRow.index].name}
                      iself={`${userIsA ? 'A' : 'B'}`}
                      other={`${userIsA ? 'B' : 'A'}`}

                      aStatus={
                          (data[virtualRow.index].A && data[virtualRow.index]['A'].status)
                              ? data[virtualRow.index]['A'].status
                              : 0
                      }
                      aTime={
                          (data[virtualRow.index].A && data[virtualRow.index]['A'].t)
                              ? data[virtualRow.index]['A'].t
                              : 0
                      }
                      bStatus={
                          (data[virtualRow.index].B && data[virtualRow.index]['B'].status)
                              ? data[virtualRow.index]['B'].status
                              : 0
                      }
                      bTime={
                          (data[virtualRow.index].B && data[virtualRow.index]['B'].t)
                              ? data[virtualRow.index]['B'].t
                              : 0
                      }
                      aFirst={
                          (data[virtualRow.index].A && data[virtualRow.index]['A'].first)
                              ? data[virtualRow.index]['A'].first
                              : 0
                      }
                      aMiddle={
                          (data[virtualRow.index].A && data[virtualRow.index]['A'].middle)
                              ? data[virtualRow.index]['A'].middle
                              : 0
                      }
                      bFirst={
                          (data[virtualRow.index].B && data[virtualRow.index]['B'].first)
                              ? data[virtualRow.index]['B'].first
                              : 0
                      }
                      bMiddle={
                          (data[virtualRow.index].B && data[virtualRow.index]['B'].middle)
                              ? data[virtualRow.index]['B'].middle
                              : 0
                      }
                  >
                    
                  </LinuBaun>
                </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}