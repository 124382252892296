import React from "react";
import { useEffect, useState } from "react";

import ThumbUp from "../img/ThumbUp.svg"
import IconThumb from "./IconThumb";

import rdbUpdate from "../firebase/rdbUpdate";
import mannaNafnaStelpuHeiti from "../data/mannaNafnaStelpuHeiti";
import { useAtom } from "jotai";
import { userNameAtom } from "./newBaunaAtoms";
// import { baunaObjAtom } from "./newBaunaAtoms";
import { imyselfAtom } from "./newBaunaAtoms";
import { partnerAtom } from "./newBaunaAtoms";
import { newBaunaDataAtom } from "./newBaunaAtoms";
import { fbDataAtom } from "./newBaunaAtoms";
import { showStatusesAtom } from "./newBaunaAtoms";
import { showOnlyAtom } from "./newBaunaAtoms";
import { allListStatesAtom } from "./newBaunaAtoms";
// import { iselfStatusAtom } from "./newBaunaAtoms";

import "./LinuBaun.css";

function LinuBaun({
  baunaNafn: nafn,
  iself: iselfLetter,
  other: otherLetter,
  aStatus: aStatusNr,
  bStatus: bStatusNr,
  aTime: timestampA,
  bTime: timestampB,
  aFirst: firstA,
  aMiddle: middleA,
  bFirst: firstB,
  bMiddle: middleB
}) {
    const [AtomUserName] = useAtom(userNameAtom);
    const [AtomImyself] = useAtom(imyselfAtom);
    const [AtomPartner] = useAtom(partnerAtom);
    const [newBaunaDataAtm] = useAtom(newBaunaDataAtom);

    const [allListStatesAtm] = useAtom(allListStatesAtom);
    
    const [statusClickCount, setStatusClickCount] = useState(0);
    const [newStatusKannski, setNewStatusKannski] = useState(-1);
    const [newStatusVilEkki, setNewStatusVilEkki] = useState(-1);

    const [clickedKannski, setClickedKannski] = useState(false);
    const [clickedVilEkki, setClickedVilEkki] = useState(false);

    const [myStatus, setMyStatus] = useState(0);

    const statusText = (statusNumber) => {
        let t;
        switch (statusNumber) {
            case undefined: t="ósvarað"; break;
            case 0: t="ósvarað"; break;
            case 1: t="Kannski"; break;
            case 2: t="VilEkki"; break;
            default: t="ósvarað"; break;
        } 
        return t
    }

    const newName = !(mannaNafnaStelpuHeiti.includes(nafn)) ? true : false;

    

    // let StatusNrA = baunaObject['A'] && baunaObject['A'].status
    //   ? baunaObject['A']['status']
    //   : "-1"
    // let StatusNrB = baunaObject['B'] && baunaObject['B']['status']
    //   ? baunaObject['B']['status']
    //   : "-1"

    let whoIs = {A: aStatusNr, B: bStatusNr}
    // let whoIs = {A: StatusNrA, B: StatusNrB}
    let iselfStatusNr = whoIs[AtomImyself]
    let otherStatusNr = whoIs[AtomPartner]


    const timeAnswered = {A: timestampA, B: timestampB}
    const imyselfTimeStamp = timeAnswered[AtomImyself]
    const partnerTimeStamp = timeAnswered[AtomPartner]

    const statusClass = {A: statusText(aStatusNr), B: statusText(bStatusNr)}

    // let iselfSTATUS = statusClass[iselfLetter];
    // let otherSTATUS = statusClass[otherLetter];

    // SETiselfStatusAtm(iselfSTATUS)

    const waitingForYou = (otherStatusNr > 0) && !(iselfStatusNr > 0) ? true : false;

    const nameLocation = {
        A: {first: firstA !== 0 ? firstA : false, middle: middleA !== 0 ? middleA : false}, 
        B: {first: firstB !== 0 ? firstB : false, middle: middleB !== 0 ? middleB : false}}

    let matchStatus = 
        (aStatusNr && bStatusNr)
            ? (aStatusNr === bStatusNr && aStatusNr === 1)
                ?  "match-kannski" 
                : (aStatusNr === bStatusNr && aStatusNr===2)
                    ? "match-vilekki"
                    : "match-none"
            : "";


    const mod = (modulus,number,plus=0) => number%modulus+plus;

    let zero = 0;

    const ClickOptionKannski = () => {
        if (clickedVilEkki) {
          setClickedVilEkki(false)
        }
        setClickedKannski(!clickedKannski);
        // clickedVilEkki ? rdbUpdate(AtomImyself, nafn, 1) : rdbUpdate(AtomImyself, nafn, 0)
        if (clickedKannski === false) {
          console.clear()
          rdbUpdate(AtomImyself, nafn, 1)
          // setMyStatus(1)
          console.log('.........should be updating to ---- 1')
        }
        if (clickedKannski === true) {
          console.clear()
          rdbUpdate(AtomImyself, nafn, 0)
          // setMyStatus(0)
          console.log('.........should be updating to ---- 0')
        }
    }
    
    const ClickOptionVilEkki = () => {
      if (clickedKannski) {
        setClickedKannski(false)
      }
      setClickedVilEkki(!clickedVilEkki);
      
      if (clickedVilEkki === false) {
        console.clear()
        rdbUpdate(AtomImyself, nafn, 2)
        // setMyStatus(2)
        console.log('.........should be updating to ---- 2')
      }
      if (clickedVilEkki === true) {
        console.clear()
        rdbUpdate(AtomImyself, nafn, 0)
        // setMyStatus(0)
        console.log('.........should be updating to ---- 0')
      }
    }


    const ClickCountUp = () => {
        let clickCount = statusClickCount+1;

        setStatusClickCount(clickCount)
        setMyStatus(mod3(statusClickCount+1))
        
        console.log(`${nafn}: status=${mod3(statusClickCount+1)} -- ClickCount = ${statusClickCount+1}`);
    }
    const mod3 = (number) => {
        return number%3
    }

    // useEffect(() => {
    //     console.log("....myStatus is now = ", myStatus)
    //     rdbUpdate(AtomImyself, nafn, myStatus)
    // }, [myStatus]);

    const dagStimpla = (t) => {
        const man = [ "jan", "feb", "mar", "apr", "maí", "jún", "júl", "ágú", "sep", "okt", "nóv", "des" ];
        const d = new Date(t);
        const d1 = d.getDate(); // _dagur
        const d2 = man[d.getMonth()]; // _man
        const d3 = d.getFullYear(); // _fullAr
        const dagStimpill = d1 + "." + d2 + "." + d3;
    
        return dagStimpill;
    }

    const LogStatus = (event) => {
      // console.log(`event`)
      // console.log(event)
      // console.log(`event.target.children[1].innerText:`)
      // console.log(event.target.children[1].innerText)

      let baunaObject = newBaunaDataAtm.filter(nafn => nafn.name === nafn)[0];
      console.log('baunaObject from "newBaunaDataAtm": ')
      console.log(baunaObject)
      console.log(`
        {
          baunaNafn: ${nafn},
          iself: ${AtomImyself},
          other: ${AtomPartner},
          aStatus: ${aStatusNr},
          bStatus: ${bStatusNr},
          aTime: ${timestampA},
          bTime: ${timestampB},
          aFirst: ${firstA},
          aMiddle:${middleA},
          bFirst: ${firstB},
          bMiddle: ${middleB}
        
      }`)


      // console.log(`event.target.className ===> ${event.target.className}`)
      // console.log(`['SammalKannski'].include = ${allListStatesAtm['SammalKannski'].include} ['SammalKannski'].showOnly = ${allListStatesAtm['SammalKannski'].showOnly}`)
      // console.log(`['SammalKannski'].include = ${allListStatesAtm['SammalKannski'].include} ['SammalKannski'].showOnly = ${allListStatesAtm['SammalKannski'].showOnly}`)


      // console.log(`STATUS # : iself = ${iselfStatusNr} --  other = ${otherStatusNr}`)
    }
    const showOnlyKannski = allListStatesAtm.Kannski.showOnly ? true : false;
    const showOnlyVilEkki = allListStatesAtm.VilEkki.showOnly ? true : false;
    const showOnlyTheRest = allListStatesAtm.TheRest.showOnly ? true : false;
    const showOnlySammalKannski = allListStatesAtm.SammalKannski.showOnly ? true : false;
    const showOnlySammalVilEkki = allListStatesAtm.SammalVilEkki.showOnly ? true : false;
    const showOnlyBidurSvars    = allListStatesAtm.BidurSvars.showOnly    ? true : false;
    const showOnly = (
      showOnlyKannski ||
      showOnlyVilEkki || 
      showOnlyTheRest ||
      showOnlySammalKannski ||
      showOnlySammalVilEkki ||
      showOnlyBidurSvars
    ) ? true : false;

  const sammalaKannskiTRUE = iselfStatusNr === 1 && otherStatusNr === 1;
  const sammalaVilEkkiTRUE = iselfStatusNr === 2 && otherStatusNr === 2;
  const iselfHaveAnswered  = iselfStatusNr === 1 || iselfStatusNr === 2;
  const partnerHasAnswered = otherStatusNr === 1 || otherStatusNr === 2;
  
  const hideKannski = !allListStatesAtm.Kannski.include && iselfStatusNr===1  && !showOnly;
  const hideVilEkki = !allListStatesAtm.VilEkki.include && iselfStatusNr===2  && !showOnly;
  const hideTheRest = !allListStatesAtm.TheRest.include && !iselfHaveAnswered && !showOnly;
  const hideSammalKannski  = !allListStatesAtm.SammalKannski.include && sammalaKannskiTRUE && !showOnly;
  const hideSammalVilEkki  = !allListStatesAtm.SammalVilEkki.include && sammalaVilEkkiTRUE && !showOnly;
  const hideBidurSvars     = !allListStatesAtm.BidurSvars.include    && partnerHasAnswered && !iselfHaveAnswered && !showOnly;

  const dontIncludeIsTRUE = ( 
    hideKannski
      || hideVilEkki
      || hideSammalKannski
      || hideSammalVilEkki
      || hideBidurSvars
      || hideTheRest
  ) ? true : false;
  const includeIsTRUE = !dontIncludeIsTRUE;

  
  
  return (
    <div key={nafn} 
      className={`Card 
          ${statusClass[AtomImyself]}
          ${hideKannski ? "TIPEX-HIDE" : ""}
          ${hideVilEkki ? "TIPEX-HIDE" : ""}
          ${sammalaKannskiTRUE && !hideKannski ? "sammalaCARDKannski" : ""}
          ${sammalaVilEkkiTRUE && !hideKannski ? "sammalaCARDVilEkki" : ""}
      `}
      onClick={LogStatus}
    >
      <div className="imyselfTimeStamp" id={imyselfTimeStamp === 0 ? "vantar-svar" : ""}>
        {/* {imyselfTimeStamp !== 0 ? new Date(imyselfTimeStamp).toDateString() : "Eftir að svara"} */}
        {imyselfTimeStamp !== 0 && (iselfStatusNr === 1 || iselfStatusNr === 2)
          ? dagStimpla(imyselfTimeStamp) : "Eftir að svara"}
      </div>
      {/* <img src={BumbuBaun} alt={`Bio pic for ${nafn}`} id={`${partnerHasAnswered}`}/> */}
      <h2 
        // className={`${newName ? "newName" : ""} baunanafn ${statusClass[AtomImyself]===1 ? "baunanafn-Kannski" : ""} ${yellowStyle ? "yellowStyle" : ""}`}
        className={`${newName ? "newName" : ""}  NAFN-LARGE-${statusClass[AtomImyself]}`}
        id={waitingForYou ? "waiting-for-you" : `NAFN-LARGE-${statusClass[AtomImyself]}`}    
        // onClick={ClickCountUp}   
      >
        {`${nafn}`}
      </h2>
      <div className="partnerTimeStamp" 
        id={partnerTimeStamp === 0 || (otherStatusNr !== 1 && otherStatusNr !== 2)
            ? "vantar-svar"
            : ""}
      >
        {/* {partnerTimeStamp !== 0 ? new Date(partnerTimeStamp).toDateString() : "Eftir að svara"} */}
        {partnerTimeStamp !== 0 && (otherStatusNr === 1 || otherStatusNr === 2)
          ? dagStimpla(partnerTimeStamp)
          : "Eftir að svara"}
      </div>

      <span className={`answer-LARGE ${statusClass[AtomImyself]}`} id={`answer-LARGE-${statusClass[AtomImyself]}`}>
        {`${statusClass[AtomImyself]} `}
      </span>

      <div className={(matchStatus === "match-kannski" || matchStatus === "match-vilekki") ? "sammala-show" : "hide-sammala"} 
          id={
            iselfStatusNr === 1 && otherStatusNr === 1
              ? "sammala-Kannski" 
              : (iselfStatusNr === 2) 
                ? "sammala-VilEkki" 
                : "hide-sammala"
          }
      >
        {
          "SAMMÁLA"
        // iselfStatusNr === 1 
        //   ? `Sammála -> Kannski`
        //   : (iselfStatusNr === 2)
        //       ? "Sammála -> Nei"
        //       : ""
        }
      </div>

      
      
      <span className="status imyselfAnswer" 
        id={`${statusClass[AtomImyself]}`}
        // onClick={() => ClickCountUp()}
      >
        <span id="username">{`${AtomUserName.iself}:`}</span>
        <span id="userstatus">{`${statusClass[AtomImyself]} `}</span>
      </span>





        {/* // NEW ANSWER BUTTONS   */}
        <div className="status answer-options imyselfAnswer" 
          id={"MyAnswerOption-Kannski"}
        >
          <span className={`answeroption option-Kannski ${clickedKannski ? "ClickUP" : ""} ${clickedVilEkki ? "ClickDOWN" : ""}`} 
            id="answeroption1-userstatus" 
            onClick={() => ClickOptionKannski()}>
              {"+"}
          </span>
          <span className={`answeroption option-VilEkki ${clickedKannski ? "ClickUP" : ""} ${clickedVilEkki ? "ClickDOWN" : ""}`} id="answeroption2-userstatus" onClick={() => ClickOptionVilEkki()}>{"-"}</span>
        </div>


        {/* // NEW ANSWER Thumbs-Buttons  */}
        {/* <div className="answerthumbs" 
          id={"MyAnswerOption-Kannski"}
        >
          <div className={`thumbup ${clickedKannski ? "ClickUP" : ""} ${clickedVilEkki ? "ClickDOWN" : ""}`} 
            id={clickedVilEkki ? "clicked-ThumbUp" : "ThumbUp"}
            onClick={() => ClickOptionKannski()}
          >
            <IconThumb id={clickedVilEkki ? "clicked-upthumb" : "upthumb"} />
          </div>
          <div className={`thumbdown ${clickedKannski ? "ClickUP" : ""} ${clickedVilEkki ? "ClickDOWN" : ""}`} 
            id={clickedVilEkki ? "clicked-ThumbDown" : "ThumbDown"}
            onClick={() => ClickOptionVilEkki()}
          >
            <IconThumb id={clickedVilEkki ? "clicked-downthumb" : "downthumb"} />
          </div>
        </div> */}
        




      <span className={`status partnerAnswer ${(partnerTimeStamp === 0 && imyselfTimeStamp !==0) ? "hide-now" : ""}`} 
        id={waitingForYou ?  "waiting-for-me" : statusClass[AtomPartner]}
      >
        <span id="username">{`${AtomUserName.other}:`}</span>
        <span id="userstatus">{`${statusClass[AtomPartner]} `}</span>
      </span>

      <span className="status partnerAnswer" 
        id={waitingForYou ?  "cover-over" : "hide-now"}
      >
        ?
      </span>

    </div>
  );
}

export default LinuBaun;