import { ref, set } from "firebase/database";
import { rdb } from "./realtimeConfig";

export function rdbUpdate (forUser, baunaNafn, statusU, firstU=true,middleU=true,tU=Date.now()) {
  const infoU = {
    status: statusU,
    first:firstU,
    middle:middleU,
    t:tU
  }
  set(ref(rdb, 'stelpubaunir/' + baunaNafn + '/' + forUser + '/'), infoU);
}

export function rdbComment (forUser, baunaNafn, comment, tU=Date.now()) {
  set(ref(rdb, 'stelpubaunir/' + baunaNafn + '/' + forUser + '/comment/'), comment);
}

export default rdbUpdate;