import React, {useState} from "react";
import { useAtom } from "jotai";

import { filterSearchAtom } from "./newBaunaAtoms";
import { filteredSearchBaunaHeiti } from "./newBaunaAtoms";

export default function SearchFilterInput() {
  const [textEntered, setTextEntered] = useState(false)
  const [filterSearch, filterSearchSet] = useAtom(filterSearchAtom);
  const [filtered] = useAtom(filteredSearchBaunaHeiti);

  const ClearButton = () => {
    const clearTextClicker = (evt) => {
      document.querySelector('#search-input').value = "";
      filterSearchSet("")
    }
    return (
      <>
        <div 
          className={filterSearch.length > 0 ? "clear-button text-to-erase" : "clear-button"}
          id={filterSearch.length > 0 ? "text-to-erase" : ""}
          onClick={(evt) => clearTextClicker(evt)}
        >
          X
        </div>
      </>
    )
  }
  return (
    <div className="search-box">
        <div className="search-row">
            <div className="space"></div>
            {/* <div className="search-input-label"> Baunanafn: </div> */}
            <input id="search-input" name="search-input" value={filterSearch} className="search-input" onChange={(evt) => filterSearchSet(evt.target.value)} />
            <ClearButton />
            <div className="space"></div>
        </div>
    </div>
  );
}
