import React from "react";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { filteredSearchBaunaHeiti } from "./newBaunaAtoms";
import { filteredStatusAtom } from "./newBaunaAtoms";
import { newBaunaDataAtom } from "./newBaunaAtoms";

import SearchFilterInput from "./SearchFilterInput";
import { IconThumbGREEN } from "./IconThumb";
import { IconThumbRED } from "./IconThumb";
import { allListStatesAtom } from "./newBaunaAtoms";
import { showKannskiAtom } from "./newBaunaAtoms";
import { showStatusesAtom } from "./newBaunaAtoms";
import { sammalaKannskiAtom } from "./newBaunaAtoms";
import { sammalaVilEkkiAtom } from "./newBaunaAtoms";
import { getBidurSvars } from "../auxFunctions/getBySTATUS";

import "./StatsDashboard.css";

import mannaNafnaStelpuHeiti from "../data/mannaNafnaStelpuHeiti";

const StatsDashboard = ({newBaunir, iself, other}) => {
    const [filtered] = useAtom(filteredSearchBaunaHeiti);
    const [filteredStatusAtm] = useAtom(filteredStatusAtom);
    const [sammalaKannskiAtm] = useAtom(sammalaKannskiAtom);
    const [sammalaVilEkkiAtm] = useAtom(sammalaVilEkkiAtom);
    const [newBaunaDataAtm] = useAtom(newBaunaDataAtom);

    const [allListStatesAtm, SETallListStatesAtm] = useAtom(allListStatesAtom);

    const [showStatusesAtm, SETshowStatusesAtm] = useAtom(showStatusesAtom);

    const [clickCounter,setClickCounter] = useState(0);    
    const [currentStatus,setCurrentStatus] = useState({
        Kannski: 0,
        VilEkki: 0,
        TheRest: 0,
        SammalKannski: 0,
        SammalVilEkki: 0,
        SammalVilEkki: 0,
        BidurSvars: 0
    });

    const totalNameCOUNT = newBaunir.length;
    
    const cycle3=(number) => { return ( (number%3) + 1 )}
    const cycle4=(number) => { return ( (number%4) + 1 )}

    const KannskiA = newBaunir.filter(nafn => nafn['A'] && nafn['A'].status === 1);
    const KannskiB = newBaunir.filter(nafn => nafn['B'] && nafn['B'].status === 1);
    const KannskiCounts = {A: KannskiA.length, B: KannskiB.length};

    const VilEkkiA = newBaunir.filter(nafn => nafn['A'] && nafn['A'].status === 2);
    const VilEkkiB = newBaunir.filter(nafn => nafn['B'] && nafn['B'].status === 2);
    const VilEkkiCounts = {A: VilEkkiA.length, B: VilEkkiB.length};

    const AnsweredA = newBaunir.filter(nafn => nafn['A'] && nafn['A']['status'] && nafn['A'].status > 0);
    const AnsweredB = newBaunir.filter(nafn => nafn['B'] && nafn['B']['status'] && nafn['B'].status > 0);
    // const TheRestA  = newBaunir.filter(nafn => !nafn['A']);
    const TheRestA  = newBaunir.filter(nafn => !(nafn['A'] && nafn['A'].status === 1 || nafn['A'] && nafn['A'].status === 2));
    const TheRestB  = newBaunir.filter(nafn => !(nafn['B'] && nafn['B'].status === 1 || nafn['B'] && nafn['B'].status === 2));
    const AnsweredCount = {A: AnsweredA.length, B: AnsweredB.length}
    const TheRestCounts = {A: TheRestA.length,  B: TheRestB.length}

    const ImyselfAnswered = AnsweredCount[iself];
    const PartnerAnswered = AnsweredCount[other];

    const WaitingForB = newBaunir.filter( nafn => (nafn['A'] && nafn['A'].status > 0) && (nafn['B'] && !nafn['B'].status > 0) );
    const WaitingForA = newBaunir.filter( nafn => (nafn['B'] && nafn['B'].status > 0) && (nafn['A'] && !nafn['A'].status > 0) );
    const WaitingFor = {A: WaitingForA.length, B: WaitingForB.length}
    const WaitingForPARTNER = WaitingFor[iself];
    const WaitingForImyself = WaitingFor[other];

    const SammalaKannski = newBaunir.filter( nafn => (nafn[other] && nafn[other].status === 1) && (nafn[iself] && nafn[iself].status === 1) );
    const SammalaVilEkki = newBaunir.filter( nafn => (nafn[other] && nafn[other].status === 2) && (nafn[iself] && nafn[iself].status === 2) );

    const newNames = ( newBaunir.filter(nafn => !mannaNafnaStelpuHeiti.includes(nafn)) ).length;
    
    const statsObject = {
        for: {imyself: iself, partner: other},
        iself: iself,
        other: other,
        StatusClass:{iself: KannskiCounts[iself], other: KannskiCounts[other]},
        Kannski:    {iself: KannskiCounts[iself], other: KannskiCounts[other]},
        VilEkki:    {iself: VilEkkiCounts[iself], other: VilEkkiCounts[other]},
        TheRest:    {iself: TheRestCounts[iself], other: TheRestCounts[other]},
        Answered:   {iself: AnsweredCount[iself], other: AnsweredCount[other]},
        totalNames: totalNameCOUNT, 
        UnAnswered: {iself: totalNameCOUNT - AnsweredCount[iself], other: totalNameCOUNT - AnsweredCount[other]},
        Sammala: { Kannski: SammalaKannski.length, Ekki: SammalaVilEkki.length }
    }

    // console.log(" --------------------------")
    // console.log('TheRestA = ', TheRestA)
    // console.log('TheRestB = ', TheRestB)
    // console.log(" --------------------------")

    const MyStatsClickHandler = (LIST) => {
        console.log(`LIST = ${LIST}`)
        SETshowStatusesAtm({
            ...showStatusesAtm,
            [LIST]: !showStatusesAtm[LIST]
        })
        setCurrentStatus({
            ...currentStatus,
            [LIST]: cycle4(currentStatus[LIST])
        })
        updateAllListStates(LIST);
    }


    const updateAllListStates = (LIST) => {
        console.clear();
        let oldStatus = currentStatus[LIST];
        let newStatus = cycle4( 1 + oldStatus )
       
        if (newStatus === 2) {
            SETallListStatesAtm({
                [LIST]: {
                    status: newStatus,
                    include: false,
                    showOnly: true
                },
                Kannski: {status: 1, include: false, showOnly: false},
                VilEkki: {status: 1, include: false, showOnly: false},
                TheRest: {status: 1, include: false, showOnly: false},
                SammalKannski: {status: 1, include: false, showOnly: false},
                SammalVilEkki: {status: 1, include: false, showOnly: false},
                BidurSvars: {status: 1, include: false, showOnly: false},
                [LIST]: {
                    status: newStatus,
                    include: false,
                    showOnly: true
                }
            })
        } 
        else if (newStatus === 3) {
            SETallListStatesAtm({
                Kannski: {status: 1, include: true, showOnly: false},
                VilEkki: {status: 1, include: true, showOnly: false},
                TheRest: {status: 1, include: true, showOnly: false},
                SammalKannski: {status: 1, include: true, showOnly: false},
                SammalVilEkki: {status: 1, include: true, showOnly: false},
                BidurSvars: {status: 1, include: true, showOnly: false},
                [LIST]: {
                    status: newStatus,
                    include: true,
                    showOnly: false
                }
            })
        } 
        else {
            SETallListStatesAtm({
                ...allListStatesAtm,
                [LIST]: {
                    status: newStatus,
                    include: newStatus === 4 ? false : true,
                    showOnly: false
                }
            })
        }

    }


    const PercentageSpan = (props) => {
        return (
            <span className={`dashboard-stats-percentage dashboard-percentage-${props.statClass}`}>
                {`${Math.round(100*(statsObject[props.statClass].iself / props.total),0)} %  |`}
            </span>
        )
    }
    const LabelSpan = (props) => {
        return (
            <span className={`dashboard-label dashboard-${props.statClass}-label`}>
                {props.LabelText}
            </span>
        )
    }
    const NumberSpan = (props) => {
        return (
            <span className={`dashboard-stats-number dashboard-${props.statClass}-number`}>
                {`${statsObject[props.statClass].iself}`}
            </span> 
        )
    }
    const StatsBox = (props) => {
        let LIST = props.statClass;
        return (
            <div className={`dashboard-stats-box 
                dashboard-${props.statClass}-results 
                `
            }
                // id={`${!showStatusesAtm[props.statClass] ? "hide-stat" : ""}`} 
                id={`${
                    (allListStatesAtm[props.statClass].showOnly)
                      ? "show-only" 
                      : !allListStatesAtm[props.statClass].include
                        ? "hide-stat" 
                        : ""}`}
                onClick={() => props.ClickHandler(LIST)}
            >
                <LabelSpan LabelText={props.LabelText} statClass={props.statClass}/>
                <div className="result-span">
                    <PercentageSpan statClass={props.statClass} total={props.total}/>
                    <NumberSpan statClass={props.statClass}/>
                </div>
                <ShowHideBoxes statClass={props.statClass}/>
                 
            </div>
        )
    }
    const MatchSammalaKannski = (props) => {
        let LIST = props.statClass;
        return (
            <div className="statbox-wrap SammalKannski">
            <span className="SAMMALA-LABEL" 
                        id={allListStatesAtm[props.statClass].showOnly
                        ? "show-only" 
                        : "SAMMALA-LABEL-Kannski"}
                    >
                        SAMMÁLA
                    </span>
            
            <div className={`dashboard-stats-box dashboard-${props.statClass}-results`}
                // id={`${!showStatusesAtm[props.statClass] ? "hide-stat" : ""}`} 
                id={allListStatesAtm[props.statClass].showOnly
                    ? "BARA-SammalKannski" 
                    : allListStatesAtm[props.statClass].include
                        ? "SÝNDUR-SammalKannski" 
                        : "FALINN-SammalKannski"}
                onClick={() => props.ClickHandler(LIST)}
            >
                
                <div className={`SammalKannski`}>
                </div>
                <div className="NAME-COUNT" 
                id={allListStatesAtm[props.statClass].showOnly
                    ? "show-only" 
                    : "NAME-COUNT-Kannski"}>
                    {sammalaKannskiAtm.length}
                    <span className="SAMMALA-ICON" id={allListStatesAtm[props.statClass].showOnly
                        ? "show-only" 
                        // :"SAMMALA-ICON-Kannski"}>+</span>
                        :"SAMMALA-ICON-Kannski"}><IconThumbGREEN /></span>
                </div>
                <ShowHideBoxes statClass={props.statClass}/>
                 
            </div>
            </div>
        )
    }
    const MatchSammalaVilEkki = (props) => {
        let LIST = props.statClass;
        return (
            <div className={`dashboard-stats-box dashboard-${props.statClass}-results`}
                // id={`${!showStatusesAtm[props.statClass] ? "hide-stat" : ""}`} 
                id={allListStatesAtm[props.statClass].showOnly
                    ? "BARA-SammalVilEkki" 
                    : allListStatesAtm[props.statClass].include
                        ? "SÝNDUR-VilEkki" 
                        : "FALINN-VilEkki"}
                onClick={() => props.ClickHandler(LIST)}
            >
                <div className={`SammalVilEkki`}>
                    <span className="SAMMALA-LABEL" id="SAMMALA-LABEL-VilEkki">SAMMÁLA</span>
                </div>
                <div className="NAME-COUNT" id="NAME-COUNT-VilEkki">
                    {sammalaVilEkkiAtm.length}
                    {/* <span className="SAMMALA-ICON" id="SAMMALA-ICON-VilEkki">-</span> */}
                    <span className="SAMMALA-ICON" id="SAMMALA-ICON-VilEkki"><IconThumbRED /></span>
                </div>
                <ShowHideBoxes statClass={props.statClass}/>
                 
            </div>
        )
    }
    const BidurSvarsBox = (props) => {
        let LIST = props.statClass;
        return (
            <div className={`dashboard-stats-box dashboard-${props.statClass}-results`}
                // id={`${!showStatusesAtm[props.statClass] ? "hide-stat" : ""}`} 
                id={allListStatesAtm[props.statClass].showOnly
                    ? "BARA-BidurSvars" 
                    : allListStatesAtm[props.statClass].include
                        ? "SÝNDUR-BidurSvars" 
                        : "FALINN-BidurSvars"}
                onClick={() => props.ClickHandler(LIST)}
            >
                <span className={`BidurSvars`}>
                   BÍÐUR SVARS:
                </span>
                <div className="NAME-COUNT" id="BidurSvars">
                    {BIDURsvars.length}
                </div>
                <ShowHideBoxes statClass={props.statClass}/>
                 
            </div>
        )
    }
    const ShowHideBoxes = (props) => {
        return (
            <div className="show-hide-spans">
                <span className="ShowHideSpan toggle-visability" 
                    id={allListStatesAtm[props.statClass].showOnly
                        ? props.statClass === "SammalVilEkki" 
                            ? "BARA-TAKKI-SammalVilEkki"
                            : props.statClass === "SammalKannski"
                                ? "BARA-TAKKI-SammalKannski"
                                : props.statClass === "BidurSvars"
                                    ? "BARA-TAKKI-BidurSvars"
                                    : ""
                        : allListStatesAtm[props.statClass].include
                            ? "SÝNDUR" 
                            : "FALINN"}
                >
                    {allListStatesAtm[props.statClass].showOnly
                        ? "BARA" 
                        : allListStatesAtm[props.statClass].include
                            ? "SÝNDUR" 
                            : "FALINN"}
                </span>
            </div>
        )
    }

    let BIDURsvars = getBidurSvars(newBaunaDataAtm,iself,other);


    return (
        <div className="dashboard">
            <div className="Row MatchStats">
                {/* <div className="dashboard-stats-box dashboard-search-results" id="dummy-fake-box">
                    <span className="dashboard-stats-percentage">
                        {`${Math.round(100*(filtered.length / totalNameCOUNT),0)} %`}
                    </span> 
                    <span className="number" id="filtered-number">
                        {filtered.length}
                    </span>
                </div> */}
                <div className="dashboard-stats-box dashboard-search-results">
                    <span className="dashboard-stats-percentage">
                        {`${Math.round(100*(filtered.length / totalNameCOUNT),0)} %`}
                    </span> 
                    <span className="number" id="filtered-number">
                        {filtered.length}
                    </span>
                </div>

                <MatchSammalaKannski statClass="SammalKannski" ClickHandler={(event) => MyStatsClickHandler(event)}/>
                <MatchSammalaVilEkki statClass="SammalVilEkki" ClickHandler={(event) => MyStatsClickHandler(event)}/>            
                <BidurSvarsBox statClass="BidurSvars" ClickHandler={(event) => MyStatsClickHandler(event)}/>
            </div>
            <div className="Row myStats">
                {/* <div className="dashboard-stats-box dashboard-search-results">
                    <span className="dashboard-stats-percentage">
                        {`${Math.round(100*(filtered.length / totalNameCOUNT),0)} %`}
                    </span> 
                    <span className="number" id="filtered-number">
                        {filtered.length}
                    </span>
                </div> */}

                {/* <StatsBox LabelText ="Svarað"  className="Answered"  total={totalNameCOUNT}  statClass="Answered" ClickHandler={(event) => MyStatsClickHandler(event)}/> */}
                <StatsBox LabelText ="Kannski" className="Kannski" total={ImyselfAnswered} statClass="Kannski"  ClickHandler={(event) => MyStatsClickHandler(event)}/>
                <StatsBox LabelText ="VilEkki" className="VilEkki" total={ImyselfAnswered} statClass="VilEkki"  ClickHandler={(event) => MyStatsClickHandler(event)}/>            
                <StatsBox LabelText ="Ósvarað" className="TheRest" total={totalNameCOUNT}  statClass="TheRest"  ClickHandler={MyStatsClickHandler}/>
            </div>

        </div>
    );
  }

  export default StatsDashboard;