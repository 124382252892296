import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBbQna6VCoPeyFoGOcpEBZNQ461eDahyNM",
    authDomain: "bumbubaun-95716.firebaseapp.com",
    databaseURL: "https://bumbubaun-95716-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bumbubaun-95716",
    storageBucket: "bumbubaun-95716.appspot.com",
    messagingSenderId: "937030490246",
    appId: "1:937030490246:web:7402b34ee5f7e00044d907"
  };

const app = initializeApp(firebaseConfig);
export const rdb = getDatabase(app);