import React from 'react'

import "./IconThumb.css";

export default function IconThumb() {
  return (
    <div className='iconthumb'>
        <svg width="50" height="60" viewBox="0 0 52.5 66.5" xmlns="http://www.w3.org/2000/svg">
            <path 
                className='thumb-color-fill'
                d="M92 154a42 42 0 0 0-5.6-.8c-6.8-.5-8.8-1.6-9.6-5.3-.3-1.7-.4-1.8-1.8-2.7-2-1.3-2.6-2.4-2.8-4.7-.2-1.7-.3-2-1.5-3.1a7 7 0 0 1-1.4-2c-.2-1 0-3.2.4-4.8.4-1.2.4-1.4-.2-3-1.3-3.6-.8-7 1.4-9.2 2.4-2.3 5.7-3.1 12-3.2h4.4l.1-1a25 25 0 0 0-1.9-9c-1.3-4.1-1.3-4.4-1.3-7.3 0-2.6 0-3.2.6-4.4a10 10 0 0 1 4.9-4.6c2-.7 4.7-1 5-.5.2.2.6 2.3.8 4.7.6 5.6 1 6.8 3.8 10.7 3.5 5 5.6 8.2 8.4 12.7 3.3 5.5 4 6.1 6.1 6.4.9 0 2.5.4 3.7.6 2.6.6 3 1 3.5 4.3.4 2.1.4 3 .1 6l-.7 5.7c-.5 2.7-2 7-2.9 7.8-.5.6-.6.7-3 .5-1.4 0-3-.2-3.5-.3-.8-.2-1.3 0-4.4 2.1a64.2 64.2 0 0 1-5.9 3.5 8.8 8.8 0 0 1-4.5 1.3c-1.3 0-2.7 0-4.2-.4z" 
                transform="translate(-68.6 -87.9)"
            />
            <path
                className='thumb-outline-fill'
                d="M92 154a42 42 0 0 0-5.6-.8c-6.8-.5-8.8-1.6-9.6-5.3-.3-1.7-.4-1.8-1.8-2.7-2-1.3-2.6-2.4-2.8-4.7-.2-1.7-.3-2-1.5-3.1a7 7 0 0 1-1.4-2c-.2-1 0-3.2.4-4.8.4-1.2.4-1.4-.2-3-1.3-3.6-.8-7 1.4-9.2 2.4-2.3 5.7-3.1 12-3.2h4.4l.1-1a25 25 0 0 0-1.9-9c-1.3-4.1-1.3-4.4-1.3-7.3 0-2.6 0-3.2.6-4.4a10 10 0 0 1 4.9-4.6c2-.7 4.7-1 5-.5.2.2.6 2.3.8 4.7.6 5.6 1 6.8 3.8 10.7 3.5 5 5.6 8.2 8.4 12.7 3.3 5.5 4 6.1 6.1 6.4.9 0 2.5.4 3.7.6 2.6.6 3 1 3.5 4.3.4 2.1.4 3 .1 6l-.7 5.7c-.5 2.7-2 7-2.9 7.8-.5.6-.6.7-3 .5-1.4 0-3-.2-3.5-.3-.8-.2-1.3 0-4.4 2.1a64.2 64.2 0 0 1-5.9 3.5 8.8 8.8 0 0 1-4.5 1.3c-1.3 0-2.7 0-4.2-.4zm6.2-3.2c2-.9 5.5-3.1 8-5l1.5-1.2h7.4l.6-1a24 24 0 0 0 2.4-11.8c-.4-4.5-.6-4.7-4.8-5.6-3.8-.7-4.2-1-6.4-4.3l-11.6-17.4c-2.1-3-2.2-3.2-2.9-9.3-.4-3.3-.7-3.5-2.7-1.8-2.9 2.2-3.1 3.8-1.4 8.7 1.8 5.5 2.3 7.3 2.6 10 .3 2.9.1 6.4-.4 6.4l-6.9.1c-6.3 0-6.7 0-8 .7-.7.3-1.8 1.1-2.5 1.8-1 1-1.1 1.3-1.1 2.4 0 2.4 2 5 4.4 6.2 2 .8 4.3.7 8.5-.4 4.7-1.3 5-1.6 2.9-3.2l-1.3-1-2.6.2c-1.5 0-3.4.2-4.4.3-2 .1-3.3-.4-4-1.8-.8-1.5-.5-1.8 1.4-1.8l4-.3c2.5-.4 5.7 0 8 .7 2.7 1 5.4 4.6 5.4 7.3 0 1 .2 1.6.7 2.2 1.4 2 2 3.7 1.7 5-.3.7-.2 1 .7 2.8.5 1 1 2.2 1 2.5 0 .4-.5 1-.9 1.3-.4.4-.8.8-.8 1l.7 1.3c.9 1.6 1.3 3.3 1 4l-1.7.9c-.7.2-1.4.5-1.4.7-.4.5 1.3.2 3-.6zm-10-1.6c4.9-1.1 5.4-1.5 4.4-2.8-.5-.8-1-.8-3.6 0-1.3.3-3.5.6-5.2.7-2.6.1-3.1.2-3.2.6 0 .3.4.7 1.2 1.3 1.6 1 3.1 1 6.5.2zm-2.6-6.2c3-.7 7-2 7-2.4.3-.6-.6-1.4-1.6-1.4-.5 0-2.2.3-3.9.8l-3 .8-3.1-.3c-5-.5-5.8-.3-4.7.8 1.6 1.9 5.6 2.6 9.3 1.7zm1.5-7a29 29 0 0 0 4.4-1.5c.4-.4-.3-1.2-1.6-1.8-.8-.4-1-.4-4.6.5-3.7 1-3.8 1-6.2.7l-3.8-.7c-2-.5-2.4-.5-2.4.2 0 1.4 1.4 2.4 4.6 3.2 3.1.7 5 .6 9.6-.7z" 
                transform="translate(-68.6 -87.9)"
            />
        </svg>
    </div>
  )
}

export function IconThumbGREEN() {
  return (
    <div className='iconthumbGREEN'>
        <svg width="50" height="60" viewBox="0 0 52.5 66.5" xmlns="http://www.w3.org/2000/svg">
            <path 
                className='GREENthumb-color-fill'
                d="M92 154a42 42 0 0 0-5.6-.8c-6.8-.5-8.8-1.6-9.6-5.3-.3-1.7-.4-1.8-1.8-2.7-2-1.3-2.6-2.4-2.8-4.7-.2-1.7-.3-2-1.5-3.1a7 7 0 0 1-1.4-2c-.2-1 0-3.2.4-4.8.4-1.2.4-1.4-.2-3-1.3-3.6-.8-7 1.4-9.2 2.4-2.3 5.7-3.1 12-3.2h4.4l.1-1a25 25 0 0 0-1.9-9c-1.3-4.1-1.3-4.4-1.3-7.3 0-2.6 0-3.2.6-4.4a10 10 0 0 1 4.9-4.6c2-.7 4.7-1 5-.5.2.2.6 2.3.8 4.7.6 5.6 1 6.8 3.8 10.7 3.5 5 5.6 8.2 8.4 12.7 3.3 5.5 4 6.1 6.1 6.4.9 0 2.5.4 3.7.6 2.6.6 3 1 3.5 4.3.4 2.1.4 3 .1 6l-.7 5.7c-.5 2.7-2 7-2.9 7.8-.5.6-.6.7-3 .5-1.4 0-3-.2-3.5-.3-.8-.2-1.3 0-4.4 2.1a64.2 64.2 0 0 1-5.9 3.5 8.8 8.8 0 0 1-4.5 1.3c-1.3 0-2.7 0-4.2-.4z" 
                transform="translate(-68.6 -87.9)"
            />
            <path
                className='GREENthumb-outline-fill'
                d="M92 154a42 42 0 0 0-5.6-.8c-6.8-.5-8.8-1.6-9.6-5.3-.3-1.7-.4-1.8-1.8-2.7-2-1.3-2.6-2.4-2.8-4.7-.2-1.7-.3-2-1.5-3.1a7 7 0 0 1-1.4-2c-.2-1 0-3.2.4-4.8.4-1.2.4-1.4-.2-3-1.3-3.6-.8-7 1.4-9.2 2.4-2.3 5.7-3.1 12-3.2h4.4l.1-1a25 25 0 0 0-1.9-9c-1.3-4.1-1.3-4.4-1.3-7.3 0-2.6 0-3.2.6-4.4a10 10 0 0 1 4.9-4.6c2-.7 4.7-1 5-.5.2.2.6 2.3.8 4.7.6 5.6 1 6.8 3.8 10.7 3.5 5 5.6 8.2 8.4 12.7 3.3 5.5 4 6.1 6.1 6.4.9 0 2.5.4 3.7.6 2.6.6 3 1 3.5 4.3.4 2.1.4 3 .1 6l-.7 5.7c-.5 2.7-2 7-2.9 7.8-.5.6-.6.7-3 .5-1.4 0-3-.2-3.5-.3-.8-.2-1.3 0-4.4 2.1a64.2 64.2 0 0 1-5.9 3.5 8.8 8.8 0 0 1-4.5 1.3c-1.3 0-2.7 0-4.2-.4zm6.2-3.2c2-.9 5.5-3.1 8-5l1.5-1.2h7.4l.6-1a24 24 0 0 0 2.4-11.8c-.4-4.5-.6-4.7-4.8-5.6-3.8-.7-4.2-1-6.4-4.3l-11.6-17.4c-2.1-3-2.2-3.2-2.9-9.3-.4-3.3-.7-3.5-2.7-1.8-2.9 2.2-3.1 3.8-1.4 8.7 1.8 5.5 2.3 7.3 2.6 10 .3 2.9.1 6.4-.4 6.4l-6.9.1c-6.3 0-6.7 0-8 .7-.7.3-1.8 1.1-2.5 1.8-1 1-1.1 1.3-1.1 2.4 0 2.4 2 5 4.4 6.2 2 .8 4.3.7 8.5-.4 4.7-1.3 5-1.6 2.9-3.2l-1.3-1-2.6.2c-1.5 0-3.4.2-4.4.3-2 .1-3.3-.4-4-1.8-.8-1.5-.5-1.8 1.4-1.8l4-.3c2.5-.4 5.7 0 8 .7 2.7 1 5.4 4.6 5.4 7.3 0 1 .2 1.6.7 2.2 1.4 2 2 3.7 1.7 5-.3.7-.2 1 .7 2.8.5 1 1 2.2 1 2.5 0 .4-.5 1-.9 1.3-.4.4-.8.8-.8 1l.7 1.3c.9 1.6 1.3 3.3 1 4l-1.7.9c-.7.2-1.4.5-1.4.7-.4.5 1.3.2 3-.6zm-10-1.6c4.9-1.1 5.4-1.5 4.4-2.8-.5-.8-1-.8-3.6 0-1.3.3-3.5.6-5.2.7-2.6.1-3.1.2-3.2.6 0 .3.4.7 1.2 1.3 1.6 1 3.1 1 6.5.2zm-2.6-6.2c3-.7 7-2 7-2.4.3-.6-.6-1.4-1.6-1.4-.5 0-2.2.3-3.9.8l-3 .8-3.1-.3c-5-.5-5.8-.3-4.7.8 1.6 1.9 5.6 2.6 9.3 1.7zm1.5-7a29 29 0 0 0 4.4-1.5c.4-.4-.3-1.2-1.6-1.8-.8-.4-1-.4-4.6.5-3.7 1-3.8 1-6.2.7l-3.8-.7c-2-.5-2.4-.5-2.4.2 0 1.4 1.4 2.4 4.6 3.2 3.1.7 5 .6 9.6-.7z" 
                transform="translate(-68.6 -87.9)"
            />
        </svg>
    </div>
  )
}
export function IconThumbRED() {
  return (
    <div className='iconthumbRED'>
        <svg width="50" height="60" viewBox="0 0 52.5 66.5" xmlns="http://www.w3.org/2000/svg">
            <path 
                className='REDthumb-color-fill'
                d="M92 154a42 42 0 0 0-5.6-.8c-6.8-.5-8.8-1.6-9.6-5.3-.3-1.7-.4-1.8-1.8-2.7-2-1.3-2.6-2.4-2.8-4.7-.2-1.7-.3-2-1.5-3.1a7 7 0 0 1-1.4-2c-.2-1 0-3.2.4-4.8.4-1.2.4-1.4-.2-3-1.3-3.6-.8-7 1.4-9.2 2.4-2.3 5.7-3.1 12-3.2h4.4l.1-1a25 25 0 0 0-1.9-9c-1.3-4.1-1.3-4.4-1.3-7.3 0-2.6 0-3.2.6-4.4a10 10 0 0 1 4.9-4.6c2-.7 4.7-1 5-.5.2.2.6 2.3.8 4.7.6 5.6 1 6.8 3.8 10.7 3.5 5 5.6 8.2 8.4 12.7 3.3 5.5 4 6.1 6.1 6.4.9 0 2.5.4 3.7.6 2.6.6 3 1 3.5 4.3.4 2.1.4 3 .1 6l-.7 5.7c-.5 2.7-2 7-2.9 7.8-.5.6-.6.7-3 .5-1.4 0-3-.2-3.5-.3-.8-.2-1.3 0-4.4 2.1a64.2 64.2 0 0 1-5.9 3.5 8.8 8.8 0 0 1-4.5 1.3c-1.3 0-2.7 0-4.2-.4z" 
                transform="translate(-68.6 -87.9)"
            />
            <path
                className='REDthumb-outline-fill'
                d="M92 154a42 42 0 0 0-5.6-.8c-6.8-.5-8.8-1.6-9.6-5.3-.3-1.7-.4-1.8-1.8-2.7-2-1.3-2.6-2.4-2.8-4.7-.2-1.7-.3-2-1.5-3.1a7 7 0 0 1-1.4-2c-.2-1 0-3.2.4-4.8.4-1.2.4-1.4-.2-3-1.3-3.6-.8-7 1.4-9.2 2.4-2.3 5.7-3.1 12-3.2h4.4l.1-1a25 25 0 0 0-1.9-9c-1.3-4.1-1.3-4.4-1.3-7.3 0-2.6 0-3.2.6-4.4a10 10 0 0 1 4.9-4.6c2-.7 4.7-1 5-.5.2.2.6 2.3.8 4.7.6 5.6 1 6.8 3.8 10.7 3.5 5 5.6 8.2 8.4 12.7 3.3 5.5 4 6.1 6.1 6.4.9 0 2.5.4 3.7.6 2.6.6 3 1 3.5 4.3.4 2.1.4 3 .1 6l-.7 5.7c-.5 2.7-2 7-2.9 7.8-.5.6-.6.7-3 .5-1.4 0-3-.2-3.5-.3-.8-.2-1.3 0-4.4 2.1a64.2 64.2 0 0 1-5.9 3.5 8.8 8.8 0 0 1-4.5 1.3c-1.3 0-2.7 0-4.2-.4zm6.2-3.2c2-.9 5.5-3.1 8-5l1.5-1.2h7.4l.6-1a24 24 0 0 0 2.4-11.8c-.4-4.5-.6-4.7-4.8-5.6-3.8-.7-4.2-1-6.4-4.3l-11.6-17.4c-2.1-3-2.2-3.2-2.9-9.3-.4-3.3-.7-3.5-2.7-1.8-2.9 2.2-3.1 3.8-1.4 8.7 1.8 5.5 2.3 7.3 2.6 10 .3 2.9.1 6.4-.4 6.4l-6.9.1c-6.3 0-6.7 0-8 .7-.7.3-1.8 1.1-2.5 1.8-1 1-1.1 1.3-1.1 2.4 0 2.4 2 5 4.4 6.2 2 .8 4.3.7 8.5-.4 4.7-1.3 5-1.6 2.9-3.2l-1.3-1-2.6.2c-1.5 0-3.4.2-4.4.3-2 .1-3.3-.4-4-1.8-.8-1.5-.5-1.8 1.4-1.8l4-.3c2.5-.4 5.7 0 8 .7 2.7 1 5.4 4.6 5.4 7.3 0 1 .2 1.6.7 2.2 1.4 2 2 3.7 1.7 5-.3.7-.2 1 .7 2.8.5 1 1 2.2 1 2.5 0 .4-.5 1-.9 1.3-.4.4-.8.8-.8 1l.7 1.3c.9 1.6 1.3 3.3 1 4l-1.7.9c-.7.2-1.4.5-1.4.7-.4.5 1.3.2 3-.6zm-10-1.6c4.9-1.1 5.4-1.5 4.4-2.8-.5-.8-1-.8-3.6 0-1.3.3-3.5.6-5.2.7-2.6.1-3.1.2-3.2.6 0 .3.4.7 1.2 1.3 1.6 1 3.1 1 6.5.2zm-2.6-6.2c3-.7 7-2 7-2.4.3-.6-.6-1.4-1.6-1.4-.5 0-2.2.3-3.9.8l-3 .8-3.1-.3c-5-.5-5.8-.3-4.7.8 1.6 1.9 5.6 2.6 9.3 1.7zm1.5-7a29 29 0 0 0 4.4-1.5c.4-.4-.3-1.2-1.6-1.8-.8-.4-1-.4-4.6.5-3.7 1-3.8 1-6.2.7l-3.8-.7c-2-.5-2.4-.5-2.4.2 0 1.4 1.4 2.4 4.6 3.2 3.1.7 5 .6 9.6-.7z" 
                transform="translate(-68.6 -87.9)"
            />
        </svg>
    </div>
  )
}
