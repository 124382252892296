import React from "react";
import { useEffect, useState } from "react";
import { Provider } from "jotai";
import { useAtom } from "jotai";

import { filteredSearchBaunaHeiti } from "./newBaunaAtoms";
import { filteredStatusAtom } from "./newBaunaAtoms";
import { BaunaHeitiAtom } from "./newBaunaAtoms";
import { newBaunaDataAtom } from "./newBaunaAtoms";
import { fbDataAtom } from "./newBaunaAtoms";

import { userNameAtom } from "./newBaunaAtoms";
import { imyselfAtom } from "./newBaunaAtoms";
import { partnerAtom } from "./newBaunaAtoms";

import { showKannskiAtom } from "./newBaunaAtoms";
import { showVilEkkiAtom } from "./newBaunaAtoms";
import { showTheRestAtom } from "./newBaunaAtoms";

import { showSammalaKannskiAtom } from "./newBaunaAtoms";
import { showSammalaVilEkkiAtom } from "./newBaunaAtoms";
import { showTotalListAtom } from "./newBaunaAtoms";
import { showStatusesAtom } from "./newBaunaAtoms";
import { allListStatesAtom } from "./newBaunaAtoms";
import { showOnlyAtom } from "./newBaunaAtoms";
import { sammalaKannskiAtom } from "./newBaunaAtoms";
import { sammalaVilEkkiAtom } from "./newBaunaAtoms";
import { iselfStatusAtom } from "./newBaunaAtoms";
import { showOnlyListLengthAtom } from "./newBaunaAtoms";

// Kannski': getKannskiForUser(newBaunaData, user),
// 'VilEkki': getVilEkkiForUser(newBaunaData, user),
// 'Svarad':  getAnsweredForUser(newBaunaData, user),
// 'TheRest': getTheRestForUser(newBaunaData, user),

// 'BidurSvars': getBidurSvars(newBaunaData, iself,other)
import { getKannskiForUser } from "../auxFunctions/getBySTATUS";
import { getVilEkkiForUser } from "../auxFunctions/getBySTATUS";
import { getAnsweredForUser } from "../auxFunctions/getBySTATUS";
import { getTheRestForUser } from "../auxFunctions/getBySTATUS";
import { getBidurSvars } from "../auxFunctions/getBySTATUS";
import { getSammalaKannski } from "../auxFunctions/getBySTATUS";
import { getSammalaVilEkki } from "../auxFunctions/getBySTATUS";


import "./NewStyles.css";
import "./MainApp.css";

import { rdb } from "../firebase/realtimeConfig"
import { onValue, ref } from "firebase/database";

import StatsDashboard from "./StatsDashboard";
import BaunaListi from "./BaunaListi";
import SearchFilterInput from "./SearchFilterInput";

export function MainAppA() {

    const consolelog = false;

    const [filtered] = useAtom(filteredSearchBaunaHeiti);
    const [filteredStatusAtm, SETfilteredStatusAtm] = useAtom(filteredStatusAtom);
    const [showOnlyListLengthAtm, SETshowOnlyListLengthAtm] = useAtom(showOnlyListLengthAtom);

    const [userName, userNameSET] = useAtom(userNameAtom);
    const [AtomImyself, SETAtomImyself] = useAtom(imyselfAtom);
    const [AtomPartner, SETAtomPartner] = useAtom(partnerAtom);

    const [sammalaKannskiAtm, SETsammalaKannskiAtm] = useAtom(sammalaKannskiAtom);
    const [sammalaVilEkkiAtm, SETsammalaVilEkkiAtm] = useAtom(sammalaVilEkkiAtom);
    const [iselfStatusAtm] = useAtom(iselfStatusAtom);
    
    const [showKannskiAtm] = useAtom(showKannskiAtom);
    const [showStatusesAtm] = useAtom(showStatusesAtom);
    const [allListStatesAtm] = useAtom(allListStatesAtom);
    const [showOnlyAtm] = useAtom(showOnlyAtom);

    const [showSammalaKannskiAtm] = useAtom(showSammalaKannskiAtom);
    const [showSammalaVilEkkiAtm] = useAtom(showSammalaVilEkkiAtom);
    const [showAllAtm] = useAtom(showTotalListAtom);

    const [baunaHeitaAtm, SETBaunaHeitaAtm] = useAtom(BaunaHeitiAtom);
    const [newBaunaDataAtm, SETnewBaunaDataAtm] = useAtom(newBaunaDataAtom);
    const [fbDataAtm, SETfbDataAtm] = useAtom(fbDataAtom);

    const [firebaseData, setFirebaseData] = useState({});
    const [baunaNafnaKeys, setBaunaNafnaKeys] = useState([]);
    const [allBaunaNames, setAllBaunaNames] = useState([]);
    const [newBaunaData, setNewBaunaData] = useState([]);
    const [firebaseBaunaCount, setFirebaseBaunaCount] = useState(-1);
    const [dataHasLoaded, setDataHasLoaded ] = useState(false);
    const [user, setUser] = useState('Agla');
    const [iself, setiself] = useState('A');
    const [other, setother] = useState('B');
    const [useFilteredData, setUseFilteredData] = useState([]);
    const [testLength, setTestLength] = useState(-1);

    

    useEffect(() => {
        const query = ref(rdb, "stelpubaunir");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            setBaunaNafnaKeys([...Object.keys(data)])
            // let allTheBauns = [];
            const newNames = Object.keys(data).filter(nafn => !baunaHeitaAtm.includes(nafn));
            SETBaunaHeitaAtm([...baunaHeitaAtm, ...newNames])
            
            setAllBaunaNames([...baunaHeitaAtm, ...newNames])
            setFirebaseData({...data})
            SETfbDataAtm({...data})
            
            // console.log('data: ', data)
            
            if (snapshot.exists()) {
                setFirebaseBaunaCount(Object.keys(data).length)
            }
        });
    }, []);

    useEffect(() => {
        if (firebaseData && firebaseBaunaCount > 0) {
            console.log(`useEffect(() => updateNewBaunaData(), [firebaseData, firebaseBaunaCount]);`)
            updateNewBaunaData()
        }
    }, [firebaseData, firebaseBaunaCount]);

useEffect(() => {
    // if (firebaseData && firebaseBaunaCount > 0) {
    if (true) {
        if (user === 'Baldvin') {
            userNameSET({iself: 'Baldvin', other: 'Agla'})
            setiself('B'); SETAtomImyself('B');
            setother('A'); SETAtomPartner('A')
        } else if (user === 'Agla') {
            userNameSET({iself: 'Agla', other: 'Baldvin'})
            setiself('A'); SETAtomImyself('A');
            setother('B'); SETAtomPartner('B');
        }
        // console.log(`useEffect(() => ...setUser STöff...,  [user]);`)
        // updateUserLists()
    }
}, [user]);

const updateNewBaunaData = () => {

    let newData = [];
    let KannskiNames = [],SammaKannski = [],EkkiNames = [],VilEkkiNames = [], SammaVilEkki = [];
    allBaunaNames.forEach(
        key => {
            let newDataLine = {}

            if (firebaseData[key] && firebaseData[key].A) {
                newDataLine['A'] = {...firebaseData[key].A}
            }
            if (firebaseData[key] && firebaseData[key].B) {
                newDataLine['B'] = {...firebaseData[key].B}
            }
            newDataLine['name']  = key;
            newDataLine['index']  = allBaunaNames.indexOf(key);
            newData.push({ ...newDataLine})
        } 
    )
    setNewBaunaData([...newData]);
    
    if (consolelog) {
        console.log('newData: ', newData)
    }

    SETnewBaunaDataAtm([...newData])
    // SETnewBaunaDataAtm([...newBaunaData])
    KannskiNames = newData.filter(nafn => nafn[iself] && nafn[iself].status === 1);
    VilEkkiNames = newData.filter(nafn => nafn[iself] && nafn[iself].status === 2);
    SammaKannski = KannskiNames.filter(baun => baun[other] && baun[other].status === 1)
    SammaVilEkki = VilEkkiNames.filter(baun => baun[other] && baun[other].status === 2)
    SETsammalaKannskiAtm([...SammaKannski])
    SETsammalaVilEkkiAtm([...SammaVilEkki])
}

useEffect(() => {
    if (baunaNafnaKeys && allBaunaNames.length > 0) {
        updateNewBaunaData();
    }
}, [allBaunaNames]);


const filteredSearchBaunir = newBaunaData.filter(nafn => filtered.includes(nafn.name));

const sammalaKANNSKI = getSammalaKannski(filteredSearchBaunir);
const sammalaVILEKKI = getSammalaVilEkki(filteredSearchBaunir);

const showLists = () => {
    !showAllAtm
    ? setUseFilteredData([...filteredSearchBaunir])
    : showSammalaKannskiAtm
        ? setUseFilteredData([...sammalaKANNSKI])
        : setUseFilteredData([...sammalaVILEKKI]);
}

useEffect(() => {
    showLists()
}, [showAllAtm]);




useEffect(() => {
    if (consolelog) {
        console.log(`MainApp ==> KANNSKI--ATOM = ${showKannskiAtm}`)
    }
}, [showKannskiAtm]);


useEffect(() => {
    if (iselfStatusAtm !== "undefined" && newBaunaData && Object.keys(newBaunaData).length > 0 && filteredSearchBaunir.length > 0) {
        
        console.log(`Data Has Loaded`)
        setDataHasLoaded(true);
    }
}, [newBaunaData, filteredSearchBaunir, iselfStatusAtm]);

  
  const UserButton = () => {
    const changeUser = () => (user === 'Baldvin') ? setUser('Agla') : setUser('Baldvin')
    return (
        <div className="userinfo-wrap">
            <div className="UserInfo" id={user==='Baldvin' ? "BALDVIN" : "AGLA"} 
                onClick={changeUser}
            >
                {user}
            </div>
        </div>
    );
  }
  const showOnlyKannski = allListStatesAtm.Kannski.showOnly ? true : false;
    const showOnlyVilEkki = allListStatesAtm.VilEkki.showOnly ? true : false;
    const showOnlyTheRest = allListStatesAtm.TheRest.showOnly ? true : false;
    const showOnlySammalKannski = allListStatesAtm.SammalKannski.showOnly ? true : false;
    const showOnlySammalVilEkki = allListStatesAtm.SammalVilEkki.showOnly ? true : false;
    const showOnlyBidurSvars    = allListStatesAtm.BidurSvars.showOnly    ? true : false;
    const showOnly = (
      showOnlyKannski ||
      showOnlyVilEkki || 
      showOnlyTheRest ||
      showOnlySammalKannski ||
      showOnlySammalVilEkki ||
      showOnlyBidurSvars
    ) ? true : false;

  let TestShowOnlyList = [];
  useEffect(() => {
    
    if (dataHasLoaded) {
        TestShowOnlyList = [...filteredSearchBaunir]
        
        if (showOnly) {
            TestShowOnlyList =
            allListStatesAtm.Kannski.showOnly 
                ? getKannskiForUser(filteredSearchBaunir,iself)
                : allListStatesAtm.VilEkki.showOnly
                    ? getVilEkkiForUser(filteredSearchBaunir,iself)
                    : allListStatesAtm.TheRest
                        ? getTheRestForUser(filteredSearchBaunir,iself)
                        : allListStatesAtm.SammalKannski.showOnly
                            ? sammalaKANNSKI
                            : allListStatesAtm.SammalVilEkki.showOnly
                                ? getVilEkkiForUser(filteredSearchBaunir,iself)
                                : allListStatesAtm.BidurSvars.showOnly
                                    ? getBidurSvars(filteredSearchBaunir,iself,other)
                                    : [...filteredSearchBaunir];
        }
    }
  }, [allListStatesAtm]);
    
    useEffect(() => {
        if (TestShowOnlyList.length>0) {
            SETshowOnlyListLengthAtm(TestShowOnlyList.length)
        }
        
            
    }, [TestShowOnlyList]);

   

  return (
    <div className="App">
      <SearchFilterInput />
      <div className="container">
        <UserButton />
        {
            dataHasLoaded 
            ? <StatsDashboard newBaunir={newBaunaData} iself={iself} other={other}/>
            : <div className="loading-data"></div>
        }
        
        { dataHasLoaded && allListStatesAtm.Kannski.showOnly && <BaunaListi data={getKannskiForUser(filteredSearchBaunir,iself)}/> } 
        { dataHasLoaded && allListStatesAtm.VilEkki.showOnly && <BaunaListi data={getVilEkkiForUser(filteredSearchBaunir,iself)}/> } 
        { dataHasLoaded && allListStatesAtm.TheRest.showOnly && <BaunaListi data={getTheRestForUser(filteredSearchBaunir,iself)}/> } 
        { dataHasLoaded && allListStatesAtm.SammalKannski.showOnly && <BaunaListi data={getSammalaKannski(filteredSearchBaunir,iself)}/> } 
        { dataHasLoaded && allListStatesAtm.SammalVilEkki.showOnly && <BaunaListi data={getVilEkkiForUser(filteredSearchBaunir,iself)}/> } 
        { dataHasLoaded && allListStatesAtm.BidurSvars.showOnly && <BaunaListi data={getBidurSvars(filteredSearchBaunir,iself,other)}/> } 
        { dataHasLoaded 
                && !allListStatesAtm.Kannski.showOnly
                && !allListStatesAtm.VilEkki.showOnly
                && !allListStatesAtm.TheRest.showOnly
                && !allListStatesAtm.SammalKannski.showOnly
                && !allListStatesAtm.SammalVilEkki.showOnly
                && !allListStatesAtm.BidurSvars.showOnly
            && <BaunaListi data={filteredSearchBaunir}/>
        }
        
        
      </div>
    </div>
  );
}

export default () => (
    <Provider>
      <React.Suspense fallback={<div>Loading</div>}>
        <div className="WindowSize">
          <MainAppA />
        </div>
      </React.Suspense>
    </Provider>
  );
