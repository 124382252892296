// export const getByShowKannski = (newBaunaData, showKannskiAtm=1, user='B') => {
//     console.log(`showKannskiAtm: `, showKannskiAtm);
//     console.log(`user: `, user);

//     let iselfAnswered   = newBaunaData.filter(nafn => (nafn[user]));   // newBaunaData.filter(nafn => (nafn['B']));
//     let iselfTheRest    = newBaunaData.filter(nafn => !(nafn[user]));    // newBaunaData.filter(nafn => !(nafn['B']));

//     let AnsweredA = newBaunaData.filter(nafn => (nafn['A'] && nafn['A'].status === 1 || nafn['A'] && nafn['A'].status === 2));
    

//     let TheRestA = newBaunaData.filter(nafn => (!nafn['A'] || nafn['A'] && !(nafn['A'].status === 1) && !(nafn['A'].status === 2)) )
//     let KannskiA = newBaunaData.filter(nafn => (nafn['A'] && nafn['A'].status === 1 ) )
    
    
//     let VilEkkiA = newBaunaData.filter(nafn => (nafn['A'] && nafn['A'].status === 2 ) )
//     let WithoutVilEkkiA = newBaunaData.filter( nafn => !(VilEkkiA.includes(nafn)) )
//     let WithoutKannskiA = newBaunaData.filter(nafn => !(KannskiA.includes(nafn)))

//     let AnsweredB = newBaunaData.filter(nafn => (nafn['B'] && nafn['B'].status === 1 || nafn['B'] && nafn['B'].status === 2));
//     let TheRestB = newBaunaData.filter(nafn => (!nafn['B'] || nafn['B'] && !(nafn['B'].status === 1) && !(nafn['B'].status === 2)) )
//     let KannskiB = newBaunaData.filter(nafn => (nafn['B'] && nafn['B'].status === 1 ) )
//     let WithoutKannskiB = newBaunaData.filter(nafn => !(KannskiB.includes(nafn)))
    
    

//     let iselfKannski    = newBaunaData.filter(nafn => (nafn[user] && nafn[user].status === 1));    // newBaunaData.filter(nafn => (nafn['B'] && nafn['B'].status===1))
//     let iselfNOTKannski = newBaunaData.filter(nafn => !(nafn[user] && nafn[user].status === 1));    // newBaunaData.filter(nafn => !(nafn['B'] && nafn['B'].status===1))

//     let iselfVilEkki    = newBaunaData.filter(nafn => (nafn[user] && nafn[user].status === 2));    // newBaunaData.filter(nafn => (nafn['B'] && nafn['B'].status===2))
//     let iselfNOTVilEkki = newBaunaData.filter(nafn => !(nafn[user] && nafn[user].status === 2));    // newBaunaData.filter(nafn => !(nafn['B'] && nafn['B'].status===2))

//     const KANNSKI = Object.keys(newBaunaData).filter(nafn => newBaunaData[nafn] && newBaunaData[nafn][user] && newBaunaData[nafn][user].status === 1);

//     newBaunaData.filter(nafn => !(nafn['B'] && nafn['B'].status === 1))
//     let returnList = [];
//     if (showKannskiAtm === 1) {
//         returnList = Object.keys(newBaunaData).filter(nafn => newBaunaData[nafn] && newBaunaData[nafn][user] && newBaunaData[nafn][user].status === 1)
//     }
//     if (showKannskiAtm === 2) { // Fela nöfn á KannskiLista
//         returnList = Object.keys(newBaunaData).filter(nafn => !KANNSKI.includes(nafn));
//         returnList = Object.keys(newBaunaData).filter(nafn => newBaunaData[nafn] && newBaunaData[nafn][user] && newBaunaData[nafn][user].status === 1)
//         returnList = Object.keys(baunaDataSTRINGIFIED).filter(nafn => (baunaDataSTRINGIFIED[nafn] && baunaDataSTRINGIFIED[nafn][user] && baunaDataSTRINGIFIED[nafn][user].status === 1))
//         returnList = Object.keys(baunaDataSTRINGIFIED).filter(nafn => !KANNSKI.includes(nafn));
//     }
//     if (showKannskiAtm === 3) { // Sýna bara nöfn á KAnnski Lista
//         returnList = Object.keys(newBaunaData).filter(nafn => KANNSKI.includes(nafn));
//     }
//     console.log(`showKannskiLIST: `, returnList);
//    return returnList;
// }


export const getKannskiA  = (newBaunaData) => newBaunaData.filter(nafn => (nafn['A'] && nafn['A'].status === 1 ) );
export const getVilEkkiA  = (newBaunaData) => newBaunaData.filter(nafn => (nafn['A'] && nafn['A'].status === 2 ) );
export const getAnsweredA = (newBaunaData) => newBaunaData.filter(nafn => (nafn['A'] && nafn['A'].status === 1 || nafn['A'] && nafn['A'].status === 2));
// alt version fyrir neðan:
//  export const getAnsweredA = (newBaunaData) => getKannskiA(newBaunaData).concat(getVilEkkiA(newBaunaData))

export const getKannskiB  = (newBaunaData) => newBaunaData.filter(nafn => (nafn['B'] && nafn['B'].status === 1 ) );
export const getVilEkkiB  = (newBaunaData) => newBaunaData.filter(nafn => (nafn['B'] && nafn['B'].status === 2 ) );
export const getAnsweredB = (newBaunaData) => newBaunaData.filter(nafn => (nafn['B'] && nafn['B'].status === 1 || nafn['B'] && nafn['B'].status === 2));
// alt version fyrir neðan:
//  export const getAnsweredB = (newBaunaData) => getKannskiB(newBaunaData).concat(getVilEkkiB(newBaunaData))
export const getKannskiForUser = (newBaunaData, user) => newBaunaData.filter(nafn => (nafn[user] && nafn[user].status === 1 ) );
export const getVilEkkiForUser = (newBaunaData, user) => newBaunaData.filter(nafn => (nafn[user] && nafn[user].status === 2 ) );
export const getAnsweredForUser= (newBaunaData, user) => newBaunaData.filter(nafn => nafn[user] && nafn[user]['status'] && nafn[user].status > 0)
export const getTheRestForUser = (newBaunaData, user) => newBaunaData.filter(nafn => !nafn[user] )

export const getSammalaKannski = (newBaunaData) => getKannskiForUser(newBaunaData,'A').filter(nafn => getKannskiForUser(newBaunaData,'B').includes(nafn))
export const getSammalaVilEkki = (newBaunaData) => getVilEkkiForUser(newBaunaData,'A').filter(nafn => getVilEkkiForUser(newBaunaData,'B').includes(nafn))

// export const getBidurSvars     = async (newBaunaData, iself,other) => {
//     const BIDURsvars = await getAnsweredForUser(newBaunaData,other).filter(nafn => getTheRestForUser(newBaunaData,iself).includes(nafn));
//     return BIDURsvars;
// }
export const getBidurSvars     = (newBaunaData, iself,other) => {
    const BIDURsvars = getAnsweredForUser(newBaunaData,other).filter(nafn => getTheRestForUser(newBaunaData,iself).includes(nafn));
    return BIDURsvars;
}

const getBidurAdEgSvari = (newBaunaData, user) => {
    const iself = user; const other = iself === 'A' ? 'B' : 'A'
    return getTheRestForUser(getAnsweredForUser(newBaunaData,other),iself)
}
export const getAllStatusLists = (newBaunaData, user) => {
    const iself = user; const other = iself === 'A' ? 'B' : 'A'
    return {
        'Kannski': getKannskiForUser(newBaunaData, user),
        'VilEkki': getVilEkkiForUser(newBaunaData, user),
        'Svarad':  getAnsweredForUser(newBaunaData, user),
        'TheRest': getTheRestForUser(newBaunaData, user),
        'SammalaKannski': getSammalaKannski(newBaunaData, user),
        'SammalaVilEkki': getSammalaVilEkki(newBaunaData, user),
        'BidurSvars': getBidurSvars(newBaunaData, iself,other)  
    }
}

/* 

const getAnsweredForUser= (newBaunaData, user) => newBaunaData.filter(nafn => nafn[user] )
const getTheRestForUser = (newBaunaData, user) => newBaunaData.filter(nafn => !nafn[user] ) 

const getSammalaKannski = (newBaunaData) => getKannskiForUser(newBaunaData,'A').filter(nafn => getKannskiForUser(newBaunaData,'B').includes(nafn))
const getSammalaVilEkki = (newBaunaData) => getVilEkkiForUser(newBaunaData,'A').filter(nafn => getVilEkkiForUser(newBaunaData,'B').includes(nafn))

const getBidurSvars     = (newBaunaData, iself,other) => getAnsweredForUser(newBaunaData,other)
.filter(nafn => getTheRestForUser(newBaunaData,iself).includes(nafn))

*/



const getListForUser = (newBaunaData, user) => newBaunaData.filter(nafn => (nafn[user] && nafn[user].status === 1 ) );

/*
const getKannskiForUser = (newBaunaData, user) => newBaunaData.filter(nafn => (nafn[user] && nafn[user].status === 1 ) );
const getVilEkkiForUser = (newBaunaData, user) => newBaunaData.filter(nafn => (nafn[user] && nafn[user].status === 2 ) );
const getAnsweredForUser= (newBaunaData, user) => newBaunaData.filter(nafn => nafn[user] && nafn[user]['status'] && nafn[user].status > 0)
const getTheRestForUser = (newBaunaData, user) => newBaunaData.filter(nafn => !nafn[user] )

const getSammalaKannski = (newBaunaData) => getKannskiForUser(newBaunaData,'A').filter(nafn => getKannskiForUser(newBaunaData,'B').includes(nafn))
const getSammalaVilEkki = (newBaunaData) => getVilEkkiForUser(newBaunaData,'A').filter(nafn => getVilEkkiForUser(newBaunaData,'B').includes(nafn))

const getBidurAdEgSvari = (newBaunaData, user) => {
    const iself = user; const other = iself === 'A' ? 'B' : 'A'
    return getTheRestForUser(getAnsweredForUser(newBaunaData,other),iself)
}

*/